@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box-empresa .empresa-avatar, .box-usuario .user-avatar {
  width: 50px;
  height: 50px;
  background: #FFF;
  position: absolute;
  top: -25px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  border-radius: 50%;
  border: 6px solid #20c0df; }
  .box-empresa .empresa-avatar svg, .box-usuario .user-avatar svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .box-empresa .empresa-avatar .letter, .box-usuario .user-avatar .letter {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .box-empresa .empresa-1.empresa-avatar, .box-usuario .empresa-1.user-avatar {
    border-color: #3573a0; }
  .box-empresa .empresa-5.empresa-avatar, .box-usuario .empresa-5.user-avatar {
    border-color: #eb7504; }

body {
  padding: 15px 0;
  position: relative;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px; }
  body:before {
    width: 100%;
    height: 100vh;
    background-image: -webkit-radial-gradient(#2855FF, #0E7EE8);
    background-image: radial-gradient(#2855FF, #0E7EE8);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    content: '';
    -webkit-filter: blur(25px);
            filter: blur(25px);
    top: 0;
    left: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.box-empresa {
  width: 100%;
  position: relative;
  padding: 20px;
  margin-bottom: 35px;
  color: #FFF; }
  .box-empresa:before {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.13);
    content: '';
    -webkit-filter: blur(1px);
            filter: blur(1px);
    top: 0;
    left: 0;
    box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 255, 0.1); }
  .box-empresa:first-child {
    margin-top: 43px; }
  .box-empresa:last-child {
    margin-bottom: 0; }
  .box-empresa .empresa-nome {
    margin-top: 15px;
    text-transform: capitalize;
    text-align: center;
    font-weight: 700; }
  .box-empresa .empresa-total-ligacoes {
    text-align: center;
    font-size: 18px; }

.box-usuario {
  margin-top: 2px;
  text-align: center;
  color: #FFF; }
  .box-usuario .box-text {
    position: relative;
    padding: 15px; }
    .box-usuario .box-text:before {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.13);
      content: '';
      -webkit-filter: blur(1px);
              filter: blur(1px);
      top: 0;
      left: 0;
      box-shadow: inset 0px 0px 15px 5px rgba(255, 255, 255, 0.1); }
  .box-usuario .no-gutter-custom {
    padding-top: 15px;
    padding-bottom: 15px; }
    .box-usuario .no-gutter-custom:nth-child(2) {
      padding-right: 0;
      padding-left: 30px; }
    .box-usuario .no-gutter-custom:nth-child(n+3) {
      padding-left: 0;
      padding-right: 0; }
  .box-usuario .last-block {
    border-radius: 0 10px 10px 0; }
  .box-usuario .user-avatar {
    z-index: 2;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    border-color: #FFF;
    width: 81px;
    height: 81px; }
  .box-usuario .border-dashed {
    position: relative;
    border-right: 1px dashed #FFF; }

.list-empresas {
  max-height: 580px;
  display: -webkit-flex;
  display: flex;
  overflow: hidden; }
  .list-empresas .box-empresas {
    position: relative;
    color: #FFF;
    min-width: 100%;
    overflow: hidden; }

.list-users {
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 525px; }
  .list-users.row {
    max-width: 100%; }
  .list-users .add-magic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 15px; }

.dots {
  display: table;
  margin: 15px auto; }
  .dots .dot {
    width: 15px;
    height: 15px;
    background: #084d75;
    border-radius: 50%;
    float: left;
    margin-left: 10px;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .dots .dot:first-child {
      margin-left: 0; }
    .dots .dot.marcado {
      background: #FFF; }

