@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
$rowColor: #20c0df;
$fontFamily: 'Lato', sans-serif;
$boxBg: rgba(255, 255, 255, .13);

%box-avatar {
    width: 50px;
    height: 50px;
    background: #FFF;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    border: 6px solid $rowColor;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .letter {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.empresa-1 {
        border-color: #3573a0;
    }

    &.empresa-5 {
        border-color: #eb7504;
    }
}

// 

body {
    padding: 15px 0;
    position: relative;
    margin: 0;
    // background: #031021;
    font-family: $fontFamily;
    font-size: 16px;

    &:before {
        width: 100%;
        height: 100vh;
        // background-image: url("https://wallpapers.wallhaven.cc/wallpapers/full/wallhaven-698711.png");
        // background: #3573a0;
        background-image: radial-gradient(#2855FF, #0E7EE8);
        background-position: 0px 0px;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        content: '';
        filter: blur(25px);
        top: 0;
        left: 0;
        transform: scale(1.1)
    }
}

.row-color {
    // background: $rowColor;
}

.box-empresa {
    width: 100%;
    position: relative;
    padding: 20px;
    margin-bottom: 35px;
    color: #FFF;

    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        background: $boxBg;
        content: '';
        filter: blur(1px);
        top: 0;
        left: 0;
        box-shadow: inset 0px 0px 15px 5px rgba($color: #FFF, $alpha: .1)
    }

    .empresa-avatar {
        @extend %box-avatar;
    }

    &:first-child {
        margin-top: 43px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .empresa-nome {
        margin-top: 15px;
        text-transform: capitalize;
        text-align: center;
        font-weight: 700;
    }

    .empresa-total-ligacoes {
        text-align: center;
        font-size: 18px;
    }
}

.box-usuario {
    margin-top: 2px;
    text-align: center;
    color: #FFF;

    .box-text {
        position: relative;
        padding: 15px;

        &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            background: $boxBg;
            content: '';
            filter: blur(1px);
            top: 0;
            left: 0;
            box-shadow: inset 0px 0px 15px 5px rgba($color: #FFF, $alpha: .1)
        }
    }

    .no-gutter-custom {
        padding-top: 15px;
        padding-bottom: 15px;

        &:nth-child(2) {
            padding-right: 0;
            padding-left: 30px;
        }

        &:nth-child(n+3){
            padding-left: 0;
            padding-right: 0;
        }
    }

    .last-block {
        border-radius: 0 10px 10px 0;
    }

    .user-avatar {
        @extend %box-avatar;
        z-index: 2;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        border-color: #FFF;
        width: 81px;
        height: 81px;
    }


    .border-dashed {
        position: relative;
        border-right: 1px dashed #FFF;

        // &:after {
        //     position: absolute;
        //     width: 1px;
        //     height: 100%;
        //     top: 0;
        //     right: 0;
        //     content: '';
        //     border: 1px dashed #FFF;
        // }
    }
}

.list-empresas {
    max-height: 580px; 
    display: flex;
    overflow: hidden;

    .box-empresas {
        position: relative;
        color: #FFF;
        min-width: 100%;
        overflow: hidden;
        // margin-right: 15px;

        // &:nth-last-child(){
        //     margin-right: 0;
        // }
    }
}

.list-users {
    // max-height: 580px; 
    // display: flex;
    overflow: hidden;
    width: 100%;
    position: relative;
    min-height: 525px;

    &.row {
        max-width: 100%;
    }

    .add-magic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-right: 15px;
    }

    // .box-users {
    //     position: relative;
    //     min-width: 100%;
    //     color: #FFF;
    //     overflow: hidden;
    //     // padding: 0 10px;

    //     // &:nth-last-child(){
    //     //     margin-right: 0;
    //     // }
    // }
}

.dots {
    display: table;
    margin: 15px auto;

    .dot {
        width: 15px;
        height: 15px;
        background: rgb(8, 77, 117);
        border-radius: 50%;
        float: left;
        margin-left: 10px;
        transition: all .3s;

        &:first-child {
            margin-left: 0;
        }

        &.marcado {
            background: #FFF;
        }
    }
}